<template>
    <div class="Banner">
        <img src="../../../assets/images/guide/banner3.png" alt="" v-show="url==1">
        <img src="../../../assets/images/guide/pbanner.png" alt="" v-show="url==2">
        <img src="../../../assets/images/guide/cbanner.png" alt="" v-show="url==3">
        <img src="../../../assets/images/guide/nbanner.png" alt="" v-show="url==4">
        <div class="newsdesc-tit">
            <h1 class="tit1">{{tit1}}</h1>
            <h2 class="tit2">{{tit2}}</h2>
        </div>
        <i class="el-icon-back" v-show="back" @click="goback"></i>
    </div>
</template>
<script>
export default {
   props:['tit1','tit2','url','back'],
   methods:{
       goback(){
           this.$router.go(-1)
       }
   }
}
</script>
<style lang="less" scoped>
    .Banner img{
        width:100%;
        display: block
    }
    .newsdesc-tit{
        position: absolute;
        top: 16%;
        left: 10%;
        .tit1{
            font-size: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }
        .tit2{
            margin-top: 5px;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
        }

    }
    i{
        font-size: 36px;
        position: absolute;
        top: 10%;
        left: 3%;
    }
</style>