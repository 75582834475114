<template>
  <div class="Peoples">
    <Banner tit1="名企人才库" tit2="talent pool" url="2"></Banner>

    <div class="peo">
      <div class="peo_img">
        <img src="../../assets/images/peoples/img1.png" alt="" />
      </div>
      <el-card class="box_card">
        <h2 class="text-center">名企人才库</h2>
        <p>
          网培师能力水平评价项目由中国商业联合会和上海现代服务业联合会互联网科创服务专委会分别批准，面向广大的在线教育培训从业人员提供网络培训能力的测评认证。项目采用网班教育的《网培师教育培训及评测标准》体系，并与上海市人才服务行业协会，及多家头部教育机构、大专院校等合作，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。
        </p>
        <p>
          网培师项目并得到联合国训研所UNITAR中国中心的认可并达成正式合作，参与培训及认证的网培师可申请进入联合国训研所的官方人才库。
        </p>
      </el-card>
    </div>

    <div class="cooperation">
      <h2 class="text-center">合作单位</h2>
      <div class="coopertion_cont">
        <img src="../../assets/images/peoples/logo.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import Banner from './layout/Banner'
export default {
  components: {
    Banner,
  },
}
</script>
<style lang="less" scoped>
.peo {
  width: 100%;
  padding: 20px 0px 0px;
  .peo_img {
    width: 60vw;
    margin: 0px auto;
    img {
      width: 100%;
      position: relative;
      z-index: 60;
    }
  }
  .box_card {
    width: 80vw;
    margin: 0px auto;
    padding-top: 40px;
    position: relative;
    top: -65px;
    h2 {
      margin-top: 20px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000005;
      line-height: 24px;
    }
    p {
      margin-top: 12px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c8087;
      line-height: 20px;
    }
  }
}
.cooperation {
  width: 100%;
  padding: 20px 0px;
  background: #f8f9fe;
  h2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000005;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .coopertion_cont {
    max-width: 80vw;
    margin: 0px auto;
    img {
      width: 100%;
    }
  }
}
</style>
